import { isZillionEnabled } from 'integrations/zillion/utils';
import { _apiRequest } from 'redux/actions/system';
import {
  ADD_ATTACHMENT_TO_ORDER,
  ADD_PM_TO_ORDER,
  ADD_SKU_TO_ORDER,
  ADD_STATUS_UPDATE_TO_ORDER,
  ADD_TRADE_IN_TO_ORDER,
  CLEAR_JM_PLAN_SKUS,
  CREATE_APPRAISAL,
  CREATE_APPRAISAL_ITEM,
  CREATE_ORDER,
  EDIT_APPRAISAL_ITEM,
  EDIT_ORDER_META,
  EDIT_PM_ON_ORDER,
  EDIT_SKU_ON_ORDER,
  EDIT_STATUS_UPDATE_ON_ORDER,
  GET_APPRAISAL,
  GET_APPRAISAL_ORDERS,
  GET_CARD_TRANSACTIONS,
  GET_FULFILLMENT_STATUSES,
  GET_JEWELRY_REPAIR_COSTS,
  GET_ORDER,
  GET_ORDERS_BY_TYPE,
  GET_ORDERS_BY_UUIDS,
  GET_ORDER_APPRAISALS,
  GET_RETURNABLE_ITEMS,
  GET_TAG,
  GET_TAGS,
  GET_ZILLION_AUTO_QUOTE,
  IS_EDITING_ORDER,
  IS_WHOLESALE_CLIENT,
  NEEDS_WARRANTY_ACTIVATION,
  OPEN_RETURN_ORDER,
  REMOVE_JM_PLAN_SKU,
  REMOVE_ORDER_ATTACHMENT,
  REMOVE_PM_FROM_ORDER,
  REMOVE_SKU_FROM_ORDER,
  REMOVE_STATUS_UPDATE_ON_ORDER,
  REMOVE_TRADE_IN_FROM_ORDER,
  RESET_CLIENT_FORM_ON_ORDER,
  RESET_ORDER,
  RESET_ORDER_TRADE_INS,
  SELECT_CONTACT_DATA_ELEMENT,
  SET_CLIENT_FORM_ON_ORDER,
  SET_ORDER_CUSTOMER_ID,
  SET_ORDER_PRIMARY_ATTACHMENT,
  SET_ORDER_SALE_VENDOR,
  STORE_JM_PLAN_SKU,
  UPDATE_APPRAISAL,
  UPDATE_APPRAISAL_EXTRA_INFO,
  UPDATE_ORDER,
  VALIDATE_PASSWORD,
  ZILLION_OFFER_RECEIVED,
} from 'redux/constants/action-types';
import { OrderTypeKey } from 'types/api/order';
import { SKU } from 'types/api/sku';
import history from 'utils/history';
import * as Request from 'utils/request';
import { failReqActionType, successReqActionType } from './helpers';

export const getOrder = (orderId, cb?: (data: any) => void, token?: string) => async dispatch =>
  _apiRequest({
    actionBase: GET_ORDER,
    dispatch,
    method: 'Get',
    path: `order?id=${orderId}`,
    returnPayloadOnSuccess: false,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
    unauthorizedError: 'You are not allowed to view this transaction',
    callback: data => (cb ? cb(data) : {}),
  });

export const getTag = tagId => async dispatch =>
  _apiRequest({
    actionBase: GET_TAG,
    dispatch,
    method: 'Get',
    path: `sku/tag?id=${tagId}`,
    returnPayloadOnSuccess: false,
    unauthorizedError: 'You are not allowed to view this transaction',
  });

export const getTags =
  (tagIds, type = 'ids') =>
  async dispatch => {
    const path = type === 'skus' ? `sku/tags?skuIds=${tagIds}` : `sku/tags?ids=${tagIds}`;

    return _apiRequest({
      actionBase: GET_TAGS,
      dispatch,
      method: 'Get',
      path,
      returnPayloadOnSuccess: false,
      unauthorizedError: 'You are not allowed to view this transaction',
    });
  };

export const getOrdersByType = (type, ecom) => async dispatch => {
  let path;
  if (ecom) {
    path = `orders?ecom=1`;
  } else {
    path = `orders?type=${type}`;
  }
  _apiRequest({
    actionBase: GET_ORDERS_BY_TYPE,
    dispatch,
    method: 'Get',
    path,
  });
};

export const getOrdersByUUIDs = (uuids: string[]) => async dispatch => {
  const path = `orders?uuids=${uuids.join(',')}`;

  await _apiRequest({
    actionBase: GET_ORDERS_BY_UUIDS,
    dispatch,
    method: 'Get',
    path,
  });
};

export const getOrderAppraisals = uuid => async dispatch => {
  _apiRequest({
    actionBase: GET_ORDER_APPRAISALS,
    dispatch,
    method: 'Get',
    path: `appraisals/${uuid}`,
    metaDispatch: { uuid },
  });
};

export const addSkuToOrder = (payload: any, cb?: () => void) => async (dispatch, ownState) => {
  if (!payload.non_stock && payload.quantity > 0) {
    const response = await Request.Get(`item/inventory?id=${payload.id}`);

    if (response.error) {
      console.error(response.error);

      dispatch({
        type: failReqActionType(ADD_SKU_TO_ORDER),
        payload: response.error,
      });

      return;
    }

    const locationQuantity = response.data.find(l => l.location_id === payload.locationID)?.quantity || 0;

    if (locationQuantity < payload.quantity) {
      // Not enough items of this Stock # at the location
      window.showNotification(
        'error',
        'Quantity Not Available',
        'The quantity you requested for this Stock # is not available at your selected location. Please transfer the item in order to complete the sale.'
      );

      dispatch({
        type: failReqActionType(ADD_SKU_TO_ORDER),
        payload: 'Quantity for this Stock # is not available at this location.',
      });

      return;
    }
  }

  if (payload.orderID) {
    const response = await _apiRequest({
      actionBase: ADD_SKU_TO_ORDER,
      dispatch,
      method: 'Post',
      path: 'order/item',
      payload,
    });

    if (response.error) {
      console.error(response.error);

      dispatch({
        type: failReqActionType(ADD_SKU_TO_ORDER),
        payload: 'Error adding Stock #',
      });

      return;
    }

    dispatch({
      type: successReqActionType(ADD_SKU_TO_ORDER),
      payload: { ...payload, uuid: response.data.uuid },
    });

    return response.data;
  }

  if (payload.non_stock) {
    dispatch({
      type: successReqActionType(ADD_SKU_TO_ORDER),
      payload,
    });

    return;
  }

  const existingSkus = ownState().newOrder.skus;
  const existingSkuOnOrder = Object.values(existingSkus).find((sku: SKU) => sku.sku === payload.sku);
  if (existingSkuOnOrder != null && !payload.override) {
    cb && cb();

    return;
  }

  dispatch({
    type: successReqActionType(ADD_SKU_TO_ORDER),
    payload,
  });
};

export const editSkuOnOrder = payload => async dispatch => {
  if (payload.orderItemID) {
    await _apiRequest({
      actionBase: EDIT_SKU_ON_ORDER,
      dispatch,
      method: 'Put',
      path: 'order/item',
      payload,
      returnPayloadOnSuccess: true,
      handleStatus: status => {
        switch (status) {
          case 417:
            window.showNotification(
              'error',
              'Quantity Not Available',
              `The quantity you requested for this Stock # (${payload.sku}) is not available at this location. Please transfer the item in order to complete the sale.`
            );
            break;
          case 403:
            window.showNotification(
              'error',
              'Discount not available',
              'You are not allowed to set high discount percent. Please set it lower.'
            );
            break;
          default:
            break;
        }
      },
    });
  } else {
    if (!payload.non_stock) {
      if (payload.isSku) {
        if (payload.quantity > payload.total_quantity) {
          window.showNotification(
            'error',
            'Quantity Not Available',
            'The quantity you requested for this Stock # is not available at this location. Please transfer the item in order to complete the sale.'
          );

          dispatch({
            type: failReqActionType(EDIT_SKU_ON_ORDER),
            payload: 'Quantity for this Stock # is not available at this location.',
          });

          return;
        }
      } else {
        const response = await Request.Get(`item/inventory?id=${payload.id}`);

        if (response.error) {
          console.error(response.error);

          dispatch({
            type: failReqActionType(EDIT_SKU_ON_ORDER),
            payload: response.error,
          });

          return;
        }

        const locationQuantity = response.data.find(l => l.location_id === payload.locationID)?.quantity || 0;

        if (locationQuantity < payload.quantity) {
          // Not enough items of this Stock # at the location
          window.showNotification(
            'error',
            'Quantity Not Available',
            'The quantity you requested for this Stock # is not available at this location. Please transfer the item in order to complete the sale.'
          );

          dispatch({
            type: failReqActionType(EDIT_SKU_ON_ORDER),
            payload: 'Quantity for this Stock # is not available at this location.',
          });

          return;
        }
      }
    }

    dispatch({
      type: successReqActionType(EDIT_SKU_ON_ORDER),
      payload,
    });
  }
};

export const removeSkuFromOrder = (sku: any, orderID?: number) => async dispatch => {
  const { uuid } = sku;

  if (orderID) {
    const payload = {
      ...sku,
      orderID,
      return: !sku.non_stock,
      active: false,
    };

    await _apiRequest({
      actionBase: REMOVE_SKU_FROM_ORDER,
      dispatch,
      method: 'Put',
      path: 'order/item',
      payload,
      metaDispatch: { uuid },
      handleStatus: status => {
        switch (status) {
          case 403:
            if (payload.return) {
              // Return to stock
              window.showNotification(
                'error',
                'Cannot Return To Stock',
                `You cannot return this Stock # to stock at this time. Please contact your system administrator for details.`
              );
            }
            break;
          case 417:
            if (payload.return) {
              // Return to stock
              window.showNotification(
                'error',
                'Already In Stock',
                `This Stock # is already in stock. You can only do this for out of stock Stock #s.`
              );
            }
            break;
          default:
            break;
        }
      },
    });
  } else {
    dispatch({
      type: successReqActionType(REMOVE_SKU_FROM_ORDER),
      uuid,
    });
  }
};

export const addPaymentMethodToOrder = (payload: any, orderID?: number, cb?: (data: any) => void) => async dispatch => {
  if (orderID) {
    const response = await _apiRequest({
      actionBase: ADD_PM_TO_ORDER,
      dispatch,
      method: 'Post',
      path: `order/payment`,
      payload: {
        id: orderID,
        paymentID: payload.id,
        code: payload.code,
        date: payload.date,
        amount: Number(payload.amount),
        locationID: Number(payload.locationID),
        accounting_location_id: payload.accounting_location_id
          ? Number(payload.accounting_location_id)
          : Number(payload.locationID),
        customer_id: payload.customer_id,
        card_number: payload.card_number,
        store_credit: payload.store_credit,
        payment_terms: payload.payment_terms,
        payment_number: payload.payment_number,
        retref: payload.retref,
        linked_retref: payload.linked_retref,
        tendered_amount: payload.tendered_amount && Number(payload.tendered_amount),
        payment_plan: payload.payment_plan,
      },
      metaDispatch: { paymentMethod: payload, orderID },
    });

    if (response.error) {
      console.error(response.error);
      return;
    }

    if (response.data && cb) {
      cb(response.data);
    }
  } else {
    dispatch({
      type: successReqActionType(ADD_PM_TO_ORDER),
      payload,
    });
  }
};

export const editPaymentMethodOnOrder = (payload, cb?: (data: any) => void) => async dispatch => {
  if (payload.check) {
    await _apiRequest({
      actionBase: EDIT_PM_ON_ORDER,
      dispatch,
      method: 'Put',
      path: 'order/payment',
      payload,
      handleStatus: status => {
        switch (status) {
          case 200:
            cb && cb(true);
            break;
          case 403:
            cb && cb(false);
            break;
          default:
            break;
        }
      },
    });
    return;
  }

  if (payload.orderPaymentID) {
    await _apiRequest({
      actionBase: EDIT_PM_ON_ORDER,
      dispatch,
      method: 'Put',
      path: 'order/payment',
      payload: {
        ...payload,
        id: payload.orderPaymentID,
      },
      returnPayloadOnSuccess: true,
      metaDispatch: {
        paymentMethodId: payload.paymentMethod,
      },
      handleStatus: status => {
        switch (status) {
          case 200:
            // window.showNotification('success', 'Success', 'Update successful');

            if (payload.isMove) {
              dispatch({
                type: successReqActionType(REMOVE_PM_FROM_ORDER),
                idxToRemove: payload.index,
              });
            }

            break;
          case 400:
            window.showNotification('error', 'Error', 'Update failed');
            break;
          default:
            break;
        }
      },
    });
  } else {
    dispatch({
      type: successReqActionType(EDIT_PM_ON_ORDER),
      payload,
    });
  }
};

export const removePaymentMethodFromOrder =
  (id, orderPaymentID, index, isVoided = false) =>
  async dispatch => {
    if (orderPaymentID) {
      await _apiRequest({
        actionBase: REMOVE_PM_FROM_ORDER,
        dispatch,
        method: 'Delete',
        path: `order/payment`,
        payload: { id: orderPaymentID, is_voided: isVoided },
        returnPayloadOnSuccess: true,
        metaDispatch: { idxToRemove: index },
      });
    } else {
      dispatch({
        type: successReqActionType(REMOVE_PM_FROM_ORDER),
        idxToRemove: index,
      });
    }
  };

interface CreateOrderOptions {
  navigateOnResponse: boolean;
  isMultiOrder: boolean;
}

export const createOrder =
  (
    payload,
    cb?: (response: any) => void,
    options: CreateOrderOptions = { navigateOnResponse: true, isMultiOrder: false }
  ) =>
  async (dispatch, ownState) => {
    const response = await _apiRequest({
      actionBase: CREATE_ORDER,
      dispatch,
      method: 'Post',
      path: `order`,
      payload,
    });

    if (response.error) {
      console.error(response.error);
      cb && cb(response);
      return;
    }

    if (response.status === 400) {
      window.showNotification(
        'error',
        'Error creating transaction',
        'Please check that all required information has been provided. If the problem continues, contact your system administrator.'
      );
      cb && cb(response);

      return;
    }

    if (response.status === 403) {
      window.showNotification(
        'error',
        'Error creating transaction',
        'Cannot close this transaction due to accounting month being closed. Please contact finance or adjust the date of your transaction to continue.'
      );
      cb && cb(response);

      return;
    }

    const integrations = ownState().root?.organization?.integrations;
    // TODO: Add to settings when user addition of Zillion Integration is implemented
    if (isZillionEnabled(integrations) && payload.close_date && !options.isMultiOrder) {
      dispatch({ type: GET_ZILLION_AUTO_QUOTE, payload: { autoQuote: true } });
    }

    if (response.data) {
      if (options.navigateOnResponse) {
        let url = `/transactions/${response.data.uuid}`;
        if (payload.orderType === OrderTypeKey.Repair) {
          url += '/job-info';
        }

        history.replace(url);
      }

      // Shows "New Transaction" selection if order is reset before the following orders are created
      // Block from resetting until the review component dismounts
      if (!options.isMultiOrder) {
        dispatch({ type: RESET_ORDER });
      }

      return response.data;
    }
  };

export const updateOrder = (payload, uuid?: any, cb?: () => void) => async dispatch => {
  const response = await _apiRequest({
    actionBase: UPDATE_ORDER,
    dispatch,
    method: 'Put',
    path: `order`,
    payload,
  });

  if (uuid) {
    await dispatch(getOrder(uuid));

    if (response.status === 200) {
      window.showSuccess('Transaction updated');
    }
  }

  cb && cb();
};

export const setClientFormOnOrder = payload => ({
  type: SET_CLIENT_FORM_ON_ORDER,
  payload,
});

export const resetClientFormOnorder = () => ({
  type: RESET_CLIENT_FORM_ON_ORDER,
});

export const resetOrder = (copy?: any) => ({ type: RESET_ORDER, copy });

export const setIsEditingOrder = editing => ({
  type: IS_EDITING_ORDER,
  payload: editing,
  edit: true,
});

export const setWholesaleClient = isWholesaleClient => ({
  type: IS_WHOLESALE_CLIENT,
  payload: isWholesaleClient,
});

export const selectContactDataElement = (key, id) => ({
  type: SELECT_CONTACT_DATA_ELEMENT,
  payload: { key, id },
});

export const validatePassword = (payload, orderId, edit, cb) => async dispatch => {
  const response = await _apiRequest({
    actionBase: VALIDATE_PASSWORD,
    dispatch,
    method: 'Post',
    payload,
    path: `order/approval`,
  });

  if (response.status === 401) {
    window.showError('Incorrect password');
    cb && cb(false);
    return;
  }

  if (response.error) {
    console.error(response.error);
    cb && cb(false);
    return;
  }

  if (orderId) {
    dispatch({
      type: IS_EDITING_ORDER,
      payload: response.data != null,
      edit,
    });
  }

  cb && cb(true);
};

export const addAttachmentToOrder = (orderId, data, filename, isBlob) => async dispatch => {
  let blob;
  if (isBlob) {
    blob = data;
  } else {
    blob = new Blob([data], { type: 'text/plain' });
  }
  const formData = new FormData();
  formData.append('file', blob);
  formData.append('ext', filename.split('.').pop());
  formData.append('repairID', orderId);
  await _apiRequest({
    actionBase: ADD_ATTACHMENT_TO_ORDER,
    dispatch,
    method: 'Post',
    path: `upload`,
    payload: formData,
    rawBody: true,
  });
};

export const setOrderPrimaryAttachment = (orderId, attachmentId) => async dispatch => {
  await _apiRequest({
    actionBase: SET_ORDER_PRIMARY_ATTACHMENT,
    dispatch,
    method: 'Put',
    path: `order`,
    payload: {
      primaryAttachmentId: attachmentId,
      id: orderId,
    },
    returnPayloadOnSuccess: true,
  });
};

export const removeOrderAttachment = (orderId, attachmentId) => async dispatch => {
  await _apiRequest({
    actionBase: REMOVE_ORDER_ATTACHMENT,
    dispatch,
    method: 'Put',
    path: `order`,
    payload: {
      removingAttachmentId: attachmentId,
      id: orderId,
    },
    returnPayloadOnSuccess: true,
  });
};

export const setOrderCustomerId = custId => ({
  type: SET_ORDER_CUSTOMER_ID,
  payload: custId,
});

export const setOrderSaleVendor = vendorId => ({
  type: SET_ORDER_SALE_VENDOR,
  payload: vendorId,
});

export const addStatusUpdateToOrder = payload => async dispatch => {
  await _apiRequest({
    actionBase: ADD_STATUS_UPDATE_TO_ORDER,
    dispatch,
    method: 'Post',
    path: `order/status`,
    payload,
  });
};

export const editStatusUpdateOnOrder = payload => async dispatch => {
  await _apiRequest({
    actionBase: EDIT_STATUS_UPDATE_ON_ORDER,
    dispatch,
    method: 'Put',
    path: `order/status`,
    payload: {
      ...payload,
      code: payload.code.id,
    },
    metaDispatch: { fullCode: payload.code },
    returnPayloadOnSuccess: true,
  });
};

export const removeStatusUpdateOnOrder = payload => async dispatch => {
  await _apiRequest({
    actionBase: REMOVE_STATUS_UPDATE_ON_ORDER,
    dispatch,
    method: 'Delete',
    path: `order/status`,
    payload,
    returnPayloadOnSuccess: true,
  });
};

// TODO: Unify with `updateOrder`, a separate action creator exists
// only so that we emit a more specific action (""_META) so the reducer
// knows which aspects of state to update
export const editOrderMeta = payload => async (dispatch, ownState) => {
  const response = await _apiRequest({
    actionBase: EDIT_ORDER_META,
    dispatch,
    method: 'Put',
    path: `order`,
    payload,
    returnPayloadOnSuccess: true,
  });

  if (response.error) {
    console.error(response.error);
    return;
  }

  if (response.status === 200) {
    window.showSuccess('Transaction updated');

    if (payload.reopen) {
      dispatch({
        type: IS_EDITING_ORDER,
        payload: true,
        edit: true,
      });
    }

    if (payload.closeDate) {
      dispatch({
        type: IS_EDITING_ORDER,
        payload: null,
        edit: false,
      });

      const integrations = ownState().root?.organization?.integrations;
      if (isZillionEnabled(integrations)) {
        dispatch({
          type: GET_ZILLION_AUTO_QUOTE,
          payload: { autoQuote: true },
        });
      }
    }
  } else {
    window.showError('Error updating transaction');
  }
};

export const voidOrder = orderId => async dispatch => {
  const response = await _apiRequest({
    actionBase: EDIT_ORDER_META,
    dispatch,
    method: 'Put',
    path: `order/${orderId}/void`,
  });

  if (response.error) {
    console.error(response.error);
  }
};

export const cancelOrder = payload => async dispatch => {
  const response = await _apiRequest({
    actionBase: EDIT_ORDER_META,
    dispatch,
    method: 'Put',
    path: `order/${payload.id}/cancel`,
    payload,
    returnPayloadOnSuccess: true,
  });

  if (response.error) {
    console.error(response.error);
  }
};

export const getReturnableItems = customerId => async dispatch =>
  _apiRequest({
    actionBase: GET_RETURNABLE_ITEMS,
    dispatch,
    method: 'Get',
    path: `order/items?customerID=${customerId}&returnable=true`,
  });

export const getFulfillmentStatuses = () => async dispatch =>
  _apiRequest({
    actionBase: GET_FULFILLMENT_STATUSES,
    dispatch,
    method: 'Get',
    path: `ecom/statuses`,
  });

export const openReturnOrder = (payload?: (data: any) => void) => ({
  type: OPEN_RETURN_ORDER,
  payload,
});

export const getCardTransactions = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_CARD_TRANSACTIONS,
    dispatch,
    method: 'Get',
    path: `payment/credit-card?customer_id=${uuid}`,
    metaDispatch: { uuid },
  });

export const resetZillionState = () => async dispatch => {
  dispatch({ type: ZILLION_OFFER_RECEIVED });
};

export const storeJmPlanSku = payload => ({
  type: STORE_JM_PLAN_SKU,
  payload,
});

export const removeJmPlanSku = payload => ({
  type: REMOVE_JM_PLAN_SKU,
  payload,
});

export const clearJmPlanSkus = () => ({
  type: CLEAR_JM_PLAN_SKUS,
});

export const needsWarrantyActivation = payload => ({
  type: NEEDS_WARRANTY_ACTIVATION,
  payload,
});

export const getJewelryRepairCosts = () => async dispatch =>
  _apiRequest({
    actionBase: GET_JEWELRY_REPAIR_COSTS,
    dispatch,
    method: 'Get',
    path: `repairs/jewelry-repair-costs`,
  });

export const getAppraisalOrders = () => async dispatch => {
  _apiRequest({
    actionBase: GET_APPRAISAL_ORDERS,
    dispatch,
    method: 'Get',
    path: `appraisals`,
  });
};

export const getAppraisal = uuid => async dispatch => {
  _apiRequest({
    actionBase: GET_APPRAISAL,
    dispatch,
    method: 'Get',
    path: `appraisals/${uuid}`,
  });
};

export const editAppraisalItem = (payload, appraisalUuid) => async dispatch => {
  await _apiRequest({
    actionBase: EDIT_APPRAISAL_ITEM,
    dispatch,
    method: 'Put',
    path: `appraisals/items`,
    payload: {
      ...payload,
    },
    metaDispatch: { payload },
    returnPayloadOnSuccess: true,
    callback: () => {
      dispatch(getAppraisal(appraisalUuid));
    },
  });
  window.showNotification('success', 'Appraisal item updated', 'Your appraisal item has been updated.');
};

export const createAppraisalItem = (payload, appraisalUuid) => async dispatch => {
  await _apiRequest({
    actionBase: CREATE_APPRAISAL_ITEM,
    dispatch,
    method: 'Post',
    path: `appraisals/items`,
    payload,
    metaDispatch: { payload },
    returnPayloadOnSuccess: true,
    callback: () => {
      dispatch(getAppraisal(appraisalUuid));
    },
  });
  window.showNotification('success', 'Appraisal item created', 'Your appraisal item has been created.');
};

export const createAppraisal = payload => async dispatch => {
  await _apiRequest({
    actionBase: CREATE_APPRAISAL,
    dispatch,
    method: 'Post',
    path: `appraisals`,
    payload,
  });

  const { order_uuid } = payload;

  await dispatch(getOrder(order_uuid));

  window.showNotification(
    'success',
    'Formal Appraisal Request Submitted.',
    'Your formal appraisal request has been submitted.'
  );
};

export const editOrderAppraisal = payload => async dispatch => {
  await _apiRequest({
    actionBase: UPDATE_APPRAISAL,
    dispatch,
    method: 'Put',
    path: `appraisals`,
    payload,
    returnPayloadOnSuccess: true,
  });
  window.showNotification('success', 'Appraisal updated', 'Your appraisal has been updated.');
};

export const updateAppraisalExtraInfo = payload => async dispatch => {
  await _apiRequest({
    actionBase: UPDATE_APPRAISAL_EXTRA_INFO,
    dispatch,
    method: 'Put',
    path: `appraisals/extra`,
    payload,
    returnPayloadOnSuccess: true,
  });
  window.showNotification('success', 'Appraisal updated', 'Your appraisal has been updated.');
};

export const addTradeInToOrder = (payload: any) => ({
  type: ADD_TRADE_IN_TO_ORDER,
  payload,
});

export const removeTradeInFromOrder = (payload: any) => ({
  type: REMOVE_TRADE_IN_FROM_ORDER,
  payload,
});

export const resetOrderTradeIns = () => ({
  type: RESET_ORDER_TRADE_INS,
});
