import { CreditPlan } from 'services/credit-plan-api';
import { Customer } from './customer';

export enum PaymentCodes {
  Cash = 'CASH',
  Check = 'CHECK',
  Visa = 'VISA',
  MasterCard = 'MASTERCARD',
  Discover = 'DISCOVER',
  AmericanExpress = 'AMEX',
  OtherCard = 'OTHERCARD',
  Gift = 'GIFT',
  Wire = 'WIRE',
  StoreCredit = 'SC',
  OnAccount = 'ACCT',
  Rewards = 'REWARDS',
  BankPay = 'BPAY',
}

export interface Payment {
  id: number;
  amount?: number;
  balance?: number;
  card_number?: string;
  check: boolean;
  code?: string;
  customer_id?: number;
  date?: string;
  hsn?: string;
  locationID?: number;
  accountingLocationId?: number;
  orderPaymentID?: number;
  name?: string;
  payment_terms?: string;
  payment_collection?: PaymentCollection[];
  payment_number?: string;
  retref?: string;
  store_credit: boolean;
  tendered_amount?: number;
  type?: number;
  user?: string;
  active: boolean;
  credit_plan?: CreditPlan;
  payment_plan: PaymentPlan;
}

export interface PaymentCollection {
  id: number;
  amount: number;
  balance: number;
  collection_date: string;
  customer?: Customer;
  location_id: number;
  location_name: string;
  payment_method: string;
  payment_method_id: number;
}

export interface PaymentMethod {
  id: number;
  name: string;
  code: string;
  custom?: boolean;
  active?: boolean;
}

export interface PaymentPlan {
  interest_rate: number;
  terms: number;
  start_date: string;
  end_date: string;
  next_charge_date: string;
  interest_start_date: string;
  installments: number;
  credit_plan_id: number;
  active?: boolean;
  customer_id?: number;
  order_payment_id?: number;
}
