import { Action, combineReducers, configureStore, Middleware, ThunkAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { multiOrderSlice } from 'redux/actions/multi-order';
import { baseApiV1, baseReportsApiV1 } from 'services/base-api-v1';
import { baseApiV2 } from 'services/base-api-v2';
import history from 'utils/history';
import accountingReducer from '../reducers/accountingReducer';
import casesReducer from '../reducers/casesReducer';
import clientReducer from '../reducers/clientReducer';
import clientsPageReducer from '../reducers/clientsPageReducer';
import creditCardReducer from '../reducers/creditCardReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import diamondReducer from '../reducers/diamondReducer';
import ecommerceReducer from '../reducers/ecommerceReducer';
import foreignExchangeReducer from '../reducers/foreignExchangeReducer';
import giftReducer from '../reducers/giftReducer';
import rootReducer from '../reducers/index';
import integrationsReducer from '../reducers/integrationsReducer';
import inventoryReducer from '../reducers/inventoryReducer';
import invoicesReducer from '../reducers/invoicesReducer';
import jobTypesAdminReducer from '../reducers/jobTypesAdminReducer';
import layoutsReducer from '../reducers/layoutsReducer';
import loadingReducer from '../reducers/loadingReducer';
import logsReducer from '../reducers/logsReducer';
import memoReducer from '../reducers/memoReducer';
import modalReducer from '../reducers/modalReducer';
import orderCodesReducer from '../reducers/orderCodesReducer';
import orderControllerReducer from '../reducers/orderControllerReducer';
import ordersReducer from '../reducers/ordersReducer';
import orderTypesAdminReducer from '../reducers/orderTypesAdminReducer';
import paymentMethodsReducer from '../reducers/paymentMethodsReducer';
import paymentTermsAdminReducer from '../reducers/paymentTermsAdminReducer';
import productAttributesReducer from '../reducers/productAttributesReducer';
import productReducer from '../reducers/productReducer';
import productTypesAdminReducer from '../reducers/productTypesAdminReducer';
import purchasedOrdersReducer from '../reducers/purchaseOrdersReducer';
import reportsReducer from '../reducers/reportsReducer';
import rolesReducer from '../reducers/rolesReducer';
import searchReducer from '../reducers/searchReducer';
import skuCategoriesReducer from '../reducers/skuCategoriesReducer';
import skuReducer from '../reducers/skuReducer';
import skuTransactionsReducer from '../reducers/skuTransactionsReducer';
import transfersReducer from '../reducers/transfersReducer';
import usersReducer from '../reducers/usersReducer';
import vendorsReducer from '../reducers/vendorsReducer';
import browseInventoryReducer from '../slices/browseInventorySlice';

const createRootReducer = (_history: any) =>
  combineReducers({
    router: connectRouter(_history),
    root: rootReducer,
    paymentMethods: paymentMethodsReducer,
    vendors: vendorsReducer,
    users: usersReducer,
    productAttributes: productAttributesReducer,
    skuCategories: skuCategoriesReducer,
    search: searchReducer,
    transfers: transfersReducer,
    memos: memoReducer,
    invoices: invoicesReducer,
    products: productReducer,
    skus: skuReducer,
    skuTransactions: skuTransactionsReducer,
    clients: clientReducer,
    roles: rolesReducer,
    loading: loadingReducer,
    modals: modalReducer,
    browseInventory: browseInventoryReducer,
    orders: ordersReducer,
    newOrder: orderControllerReducer,
    reports: reportsReducer,
    orderCodes: orderCodesReducer,
    orderTypes: orderTypesAdminReducer,
    paymentTerms: paymentTermsAdminReducer,
    jobTypes: jobTypesAdminReducer,
    inventory: inventoryReducer,
    productTypes: productTypesAdminReducer,
    gift: giftReducer,
    clientsPage: clientsPageReducer,
    layouts: layoutsReducer,
    cases: casesReducer,
    purchaseOrders: purchasedOrdersReducer,
    diamonds: diamondReducer,
    accounting: accountingReducer,
    dashboard: dashboardReducer,
    creditCard: creditCardReducer,
    logs: logsReducer,
    [baseApiV1.reducerPath]: baseApiV1.reducer,
    [baseReportsApiV1.reducerPath]: baseReportsApiV1.reducer,
    [baseApiV2.reducerPath]: baseApiV2.reducer,
    [multiOrderSlice.name]: multiOrderSlice.reducer,
    integrations: integrationsReducer,
    foreignExchange: foreignExchangeReducer,
    ecommerce: ecommerceReducer,
  });

const middleware: Middleware[] = [
  routerMiddleware(history),
  baseApiV1.middleware,
  baseReportsApiV1.middleware,
  baseApiV2.middleware,
];

if (process.env.REDUX_LOGGER && process.env.NODE_ENV === 'development') {
  const logger = require('redux-logger').createLogger({
    collapsed: true,
  });

  middleware.push(logger);
}

const createStore = () =>
  configureStore({
    devTools: process.env.WEB_ENV !== 'app',
    reducer: createRootReducer(history),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(middleware),
  });

let store: Store;

export const getStore = () => {
  if (!store) {
    store = createStore();
  }

  return store;
};

const getDispatch = () => getStore().dispatch;
const getState = () => getStore().getState();

export type AppDispatch = ReturnType<typeof getDispatch>;
export type RootState = ReturnType<typeof getState>;
export type Store = ReturnType<typeof createStore>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
