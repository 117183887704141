import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { Deployment } from 'utils/deployment';
import { encrypt } from 'utils/encrypt';

const apiAuthHeader = headers => {
  const token = Cookies.get('x-auth-token');

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};

export interface ApiResponse<T> {
  data: T;
}

export enum HttpMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
}

export const apiBodyMapper = (obj: any, raw = false) => {
  if (raw) {
    return obj;
  }

  return JSON.stringify(obj);
};

export const apiBodyMapperEncrypted = (obj: any, raw = false) => {
  const key = process.env.ENCRYPT_AES128_SECRET;
  const iv = CryptoJS.lib.WordArray.random(8).toString();

  if (!key) {
    return '';
  }

  const text = JSON.stringify(obj);
  const encryptedText = encrypt(text, key, iv);

  if (raw) {
    return {
      encrypted_data: encryptedText,
      iv,
    };
  }

  return JSON.stringify({
    encrypted_data: encryptedText,
    iv,
  });
};

export interface ApiResponseTransform<T> {
  (response: ApiResponse<T>): T;
}

export const apiResponseTransform = <T>(response: ApiResponse<T>): T => response?.data;

export const setQueryParams = (filters: object) => {
  const queryParams = new URLSearchParams(Object.entries(filters || {}).filter(([, value]) => value !== undefined));
  const params = queryParams.toString();
  return `${params.length > 0 ? `?${params}` : ''}`;
};

export const apiUrl = (baseRoute: string) => (path?: string) => {
  if (path) {
    if (path[0] === '?') {
      return `${baseRoute}${path}`;
    }

    return `${baseRoute}/${path}`;
  }
  return baseRoute;
};

export const baseApiV1 = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: Deployment.host,
    prepareHeaders: apiAuthHeader,
  }),
  reducerPath: 'crystalApiV1',
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});

export const baseReportsApiV1 = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: Deployment.reportsURL,
    prepareHeaders: apiAuthHeader,
  }),
  reducerPath: 'crystalReportsApiV1',
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
