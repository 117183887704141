import { CSSProperties, MouseEvent } from 'react';
import MaskedInput, { Mask, MaskedInputProps } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  allowNegative: false,
  allowLeadingZeroes: false,
};

interface MaskOptionsProps {
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: string;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: string;
  requireDecimal?: boolean;
  allowNegative?: boolean;
  integerLimit?: number;
  allowLeadingZeroes?: boolean;
}

interface CurrencyProps {
  maskOptions?: MaskOptionsProps;
  placeholder?: string;
  value?: number | string;
  name?: string;
  margin?: string;
  inputProps?: {
    min?: number;
    step: number;
    type: string;
    'aria-labelledby': string;
  };
  style?: CSSProperties;
  onBlur?: (e: MouseEvent) => void;
  onChange?: (e: MouseEvent) => void;
  onFocus?: (e: FocusEvent) => void;
  type?: string;
  disabled?: boolean;
  props?: any;
}

const CurrencyInput = ({ maskOptions = {}, ...props }: CurrencyProps) => {
  const currencyMask: Mask | ((value: string) => Mask) = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });
  return (
    /* eslint-disable-next-line */
    // @ts-ignore
    <MaskedInput mask={currencyMask} {...(props as MaskedInputProps)} className='form-control' />
  );
};

export default CurrencyInput;
