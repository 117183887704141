import i18n from 'i18next';
import {
  getAccountsReceivableBalanceReport,
  getAccountsReceivableReport,
  getClientsReport,
  getCollectionReport,
  getCostOfGoodsSoldReport,
  getDailyPaymentsReport,
  getFinanceChargesReport,
  getGiftCardsReport,
  getInventoryAdjustmentReport,
  getInventoryCaseDetailsReport,
  getInventoryCaseSummaryReport,
  getInventoryReport,
  getLinkedProductsReport,
  getMemosReport,
  getMerchItemsReport,
  getMyClientsReport,
  getOnAccountReport,
  getPaymentsReport,
  getProductPerformanceReport,
  getRepairsReport,
  getSalesPerformanceReport,
  getSalesReport,
  getSalesTaxThresholdReport,
  getStockCategoryPerformanceReport,
  getTransactionsReport,
  getVendorPerformanceReport,
  getVendorsReport,
  getWishlistReport,
} from 'redux/actions/index';

export enum ReportType {
  MySales = 1,
  SalesItems = 2,
  Transactions = 3,
  Clients = 4,
  Inventory = 5,
  Repairs = 6,
  Payments = 7,
  LinkedProducts = 8,
  Vendor = 9,
  ClientWishlist = 10,
  MyClientWishlist = 11,
  MerchandiseItems = 12,
  OnAccount = 13,
  GiftCards = 14,
  AccountingSummary = 15,
  MemoItems = 16,
  MyClients = 17,
  VendorPerformance = 18,
  SalespersonPerformance = 19,
  AccountingEndOfDay = 20,
  DailyPayments = 21,
  EcomInventoryReport = 22,
  SalesTaxThreshold = 23,
  SalesComparison = 24,
  AccountsReceivable = 25,
  TransferItems = 26,
  PurchaseOrders = 27,
  DailyOnAccount = 28,
  ProductPerformance = 29,
  CostOfGoodsSold = 30,
  CostOfGoodsSoldDetail = 31,
  InventoryCaseSummary = 32,
  InventoryCaseDetails = 33,
  CostOfGoodsSoldSummary = 34,
  CostOfGoodsSoldSummaryDetail = 35,
  StockCategoryPerformance = 36,
  TimeClock = 37,
  SalesByLocation = 38,
  AccountsReceivableBalance = 39,
  InventoryAdjustment = 40,
  Distribution = 41,
  Collection = 42,
  FinanceCharges = 43,
}

export const reportBaseRoute = '/reports';
const accountingBaseRoute = '/accounting';

export const AllReports = {
  [ReportType.SalesItems]: {
    key: 'sales',
    title: 'Sales Items',
    route: `${reportBaseRoute}/sales-items`,
    category: 'sales',
  },
  [ReportType.MySales]: {
    key: 'mySales',
    title: 'My Sales',
    route: `${reportBaseRoute}/my-sales`,
    category: 'sales',
  },
  [ReportType.Transactions]: {
    key: 'transactions',
    title: 'Transactions',
    route: `${reportBaseRoute}/transactions`,
    category: 'sales',
  },
  [ReportType.SalesByLocation]: {
    key: 'salesByLocation',
    title: 'Sales By Location',
    route: `${reportBaseRoute}/sales-by-location`,
    category: 'sales',
  },
  [ReportType.Clients]: {
    key: 'clients',
    title: 'Clients',
    route: `${reportBaseRoute}/clients`,
    category: 'clients',
  },
  [ReportType.Inventory]: {
    key: 'inventory',
    title: 'Inventory',
    route: `${reportBaseRoute}/inventory`,
    category: 'inventory',
  },
  [ReportType.Repairs]: {
    key: 'repairs',
    title: 'Repairs',
    route: `${reportBaseRoute}/repairs`,
    category: 'repairs',
  },
  [ReportType.Payments]: {
    key: 'payments',
    title: 'Payments',
    route: `${reportBaseRoute}/payments`,
    category: 'accounting',
  },
  [ReportType.DailyPayments]: {
    key: 'dailyPayments',
    title: 'Daily Payments',
    route: `${reportBaseRoute}/daily-payments`,
    category: 'accounting',
  },
  [ReportType.LinkedProducts]: {
    key: 'linkedProducts',
    title: 'Linked Products',
    route: `${reportBaseRoute}/linked-products`,
    category: 'inventory',
  },
  [ReportType.Vendor]: {
    key: 'vendor',
    title: 'Vendor',
    route: `${reportBaseRoute}/vendor`,
    category: 'inventory',
  },
  [ReportType.ClientWishlist]: {
    key: 'wishlist',
    title: 'Client Wishlist',
    route: `${reportBaseRoute}/client-wishlist`,
    category: 'clients',
  },
  [ReportType.MyClientWishlist]: {
    key: 'myWishlist',
    title: 'My Client Wishlist',
    route: `${reportBaseRoute}/my-client-wishlist`,
    category: 'clients',
  },
  [ReportType.MerchandiseItems]: {
    key: 'merchItems',
    title: 'Merchandise Items',
    route: `${reportBaseRoute}/merchandise-items`,
    category: 'inventory',
  },
  [ReportType.OnAccount]: {
    key: 'onAccount',
    title: 'On Account',
    route: `${reportBaseRoute}/on-account`,
    category: 'accounting',
  },
  [ReportType.GiftCards]: {
    key: 'giftCards',
    title: 'Gift Cards',
    route: `${reportBaseRoute}/gift-cards`,
    category: 'other',
  },
  [ReportType.AccountingSummary]: {
    key: 'accountingSummary',
    title: 'Accounting Summary',
    route: accountingBaseRoute,
    category: 'accounting',
  },
  [ReportType.MemoItems]: {
    key: 'memos',
    title: 'Memo Items',
    route: `${reportBaseRoute}/memo-items`,
    category: 'inventory',
  },
  [ReportType.MyClients]: {
    key: 'myClients',
    title: 'My Clients',
    route: `${reportBaseRoute}/my-clients`,
    category: 'clients',
  },
  [ReportType.SalespersonPerformance]: {
    key: 'salesPerformance',
    title: 'Salesperson Performance',
    route: `${reportBaseRoute}/salesperson-performance`,
    category: 'sales',
  },
  [ReportType.VendorPerformance]: {
    key: 'vendorPerformance',
    title: 'Vendor Performance',
    route: `${reportBaseRoute}/vendor-performance`,
    category: 'inventory',
  },
  [ReportType.TransferItems]: {
    key: 'transferItems',
    title: 'transfer-items',
    route: `${reportBaseRoute}/transfer-items`,
    category: 'inventory',
  },
  [ReportType.PurchaseOrders]: {
    key: 'purchaseOrders',
    title: 'purchase-orders',
    route: `${reportBaseRoute}/purchase-orders`,
    category: 'inventory',
  },
  [ReportType.AccountingEndOfDay]: {
    key: 'accountingEndOfDay',
    title: 'End Of Day Report',
    route: `${accountingBaseRoute}/end-of-day`,
    category: 'accounting',
  },
  [ReportType.EcomInventoryReport]: {
    key: 'ecomInventory',
    title: 'Ecommerce Inventory Report',
    route: `${reportBaseRoute}/ecom-inventory-report`,
    category: 'ecommerce',
  },
  [ReportType.SalesTaxThreshold]: {
    key: 'salesTaxThreshold',
    title: 'Sales Tax Threshold',
    route: `${reportBaseRoute}/sales-tax-threshold`,
    category: 'accounting',
  },
  [ReportType.AccountsReceivable]: {
    key: 'accountsReceivable',
    title: 'Accounts Receivable',
    route: `${reportBaseRoute}/accounts-receivable`,
    category: 'accounting',
  },
  [ReportType.DailyOnAccount]: {
    key: 'dailyOnAccount',
    title: 'Daily On Account',
    route: `${reportBaseRoute}/daily-on-account`,
    category: 'accounting',
  },
  [ReportType.ProductPerformance]: {
    key: 'product-performance',
    title: i18n.hasResourceBundle('en', 'reports:product-performance')
      ? i18n.t('reports:product-performance')
      : 'Product Performance',
    route: `${reportBaseRoute}/product-performance`,
    category: 'inventory',
  },
  [ReportType.CostOfGoodsSold]: {
    key: 'costOfGoodsSold',
    title: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.title')
      ? i18n.t('reports:cost-of-goods-sold.title')
      : 'Cost of Goods Sold Report',
    route: `${reportBaseRoute}/cost-of-goods-sold`,
    category: 'accounting',
  },
  [ReportType.CostOfGoodsSoldDetail]: {
    key: 'costOfGoodsSoldDetail',
    title: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.title')
      ? i18n.t('reports:cost-of-goods-sold.title')
      : 'Cost of Goods Sold Report',
    route: `${accountingBaseRoute}/cost-of-goods-sold-detail`,
    category: 'accounting',
  },
  [ReportType.CostOfGoodsSoldSummary]: {
    key: 'costOfGoodsSoldSummary',
    title: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.summary-title')
      ? i18n.t('reports:cost-of-goods-sold.summary-title')
      : 'Cost of Goods Sold Summary Report',
    route: `${reportBaseRoute}/cost-of-goods-sold-summary`,
    category: 'accounting',
  },
  [ReportType.CostOfGoodsSoldSummaryDetail]: {
    key: 'costOfGoodsSoldSummaryDetail',
    title: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.summary-title')
      ? i18n.t('reports:cost-of-goods-sold.summary-title')
      : 'Cost of Goods Sold Summary Report',
    route: `${reportBaseRoute}/cost-of-goods-sold-summary`,
    category: 'accounting',
  },
  [ReportType.TimeClock]: {
    key: 'timeClock',
    title: 'Time Clock',
    route: `${reportBaseRoute}/time-clock`,
    category: 'accounting',
  },
  [ReportType.InventoryCaseSummary]: {
    key: 'inventoryCaseSummary',
    title: 'Inventory Case Summary',
    route: `${reportBaseRoute}/inventory-case-summary`,
    category: 'inventory',
  },
  [ReportType.InventoryCaseDetails]: {
    key: 'inventoryCaseDetails',
    title: 'Inventory Case Details',
    route: `${reportBaseRoute}/inventory-case-details`,
    category: 'inventory',
  },
  [ReportType.StockCategoryPerformance]: {
    key: 'stockCategoryPerformance',
    title: i18n.hasResourceBundle('en', 'reports:stock-category-performance.title')
      ? i18n.t('reports:stock-category-performance.title')
      : 'Stock Category Performance Report',
    route: `${reportBaseRoute}/stock-category-performance`,
    category: 'inventory',
  },
  [ReportType.Distribution]: {
    key: 'distribution',
    title: i18n.hasResourceBundle('en', 'reports:distribution.title')
      ? i18n.t('reports:distribution.title')
      : 'Distribution Report',
    route: `${reportBaseRoute}/distribution`,
    category: 'inventory',
  },
  [ReportType.AccountsReceivableBalance]: {
    key: 'accountsReceivableBalance',
    title: i18n.hasResourceBundle('en', 'reports:accounts-receivable-balance.title')
      ? i18n.t('reports:accounts-receivable-balance.title')
      : 'Accounts Receivable Balance',
    route: `${reportBaseRoute}/accounts-receivable-balance`,
    category: 'accounting',
  },
  [ReportType.InventoryAdjustment]: {
    key: 'inventoryAdjustment',
    title: i18n.hasResourceBundle('en', 'reports:inventory-adjustment.title')
      ? i18n.t('reports:inventory-adjustment.title')
      : 'Inventory Adjustment Report',
    route: `${reportBaseRoute}/inventory-adjustment`,
    category: 'inventory',
  },
  [ReportType.Collection]: {
    key: 'collection',
    title: i18n.hasResourceBundle('en', 'reports:collection.title')
      ? i18n.t('reports:collection.title')
      : 'Collection Report',
    route: `${reportBaseRoute}/collection`,
    category: 'inventory',
  },
  [ReportType.FinanceCharges]: {
    key: 'financeCharges',
    title: i18n.hasResourceBundle('en', 'reports:finance-charges.title')
      ? i18n.t('reports:finance-charges.title')
      : 'Finance Charges Report',
    route: `${reportBaseRoute}/finance-charges`,
    category: 'accounting',
  },
};

export const getReportActions = {
  [ReportType.SalesItems]: getSalesReport,
  [ReportType.MySales]: getSalesReport,
  [ReportType.Transactions]: getTransactionsReport,
  [ReportType.Clients]: getClientsReport,
  [ReportType.Inventory]: getInventoryReport,
  [ReportType.Repairs]: getRepairsReport,
  [ReportType.Payments]: getPaymentsReport,
  [ReportType.DailyPayments]: getDailyPaymentsReport,
  [ReportType.LinkedProducts]: getLinkedProductsReport,
  [ReportType.Vendor]: getVendorsReport,
  [ReportType.ClientWishlist]: getWishlistReport,
  [ReportType.MyClientWishlist]: getWishlistReport,
  [ReportType.MerchandiseItems]: getMerchItemsReport,
  [ReportType.OnAccount]: getOnAccountReport,
  [ReportType.GiftCards]: getGiftCardsReport,
  [ReportType.MemoItems]: getMemosReport,
  [ReportType.MyClients]: getMyClientsReport,
  [ReportType.SalespersonPerformance]: getSalesPerformanceReport,
  [ReportType.VendorPerformance]: getVendorPerformanceReport,
  // no single fetch function for EOD report
  // [ReportType.AccountingEndOfDay]: null,
  [ReportType.SalesTaxThreshold]: getSalesTaxThresholdReport,
  [ReportType.AccountsReceivable]: getAccountsReceivableReport,
  [ReportType.AccountsReceivableBalance]: getAccountsReceivableBalanceReport,
  [ReportType.ProductPerformance]: getProductPerformanceReport,
  [ReportType.CostOfGoodsSold]: getCostOfGoodsSoldReport,
  [ReportType.InventoryCaseSummary]: getInventoryCaseSummaryReport,
  [ReportType.InventoryCaseDetails]: getInventoryCaseDetailsReport,
  [ReportType.StockCategoryPerformance]: getStockCategoryPerformanceReport,
  [ReportType.InventoryAdjustment]: getInventoryAdjustmentReport,
  [ReportType.Collection]: getCollectionReport,
  [ReportType.FinanceCharges]: getFinanceChargesReport,
};

export const ReportFilterTitles = {
  closeDateFilters: 'Close Date',
  locationIds: 'Locations',
  selectedVendors: 'Vendors',
};
export const DateFields = ['effective_date'];
export const CurrencyFields = [
  'amount',
  'discount',
  'gross_profit',
  'cost',
  'ext_price',
  'tax',
  'profit',
  'subtotal_discounts_applied',
  'applied_discounts',
  'subtotal',
  'retail',
];
export const PercentageFields = ['gross_profit_percent', 'margin'];

export interface Report {
  account_uuid: string;
  account_name: string;
  accounting_bucket_id?: number;
  amount_paid: number;
  billing_city: string;
  billing_postal: string;
  billing_street: string;
  billing_street_2: string;
  billing_state: string;
  canceled_date: string;
  close_date: string;
  cost: number;
  custom_1: string;
  custom_2: string;
  custom_3: string;
  custom_4: string;
  custom_5: string;
  customer_id: string;
  customer_uuid: string;
  customer_name: string;
  days_on_hand: number;
  days_to_sale: number;
  description: string;
  discount: number;
  discount_pct: number;
  ext_price: number;
  finance_charges: number;
  gross_profit: number;
  gross_profit_pct: number;
  internal_note: string;
  item_type_category: string;
  item_type_sub_category: string;
  job_type?: string;
  last_pay_date: string;
  location_id: number;
  location_name: string;
  market_cost: number;
  memo: string;
  merch_record_id: string;
  merch_uuid: string;
  note: string;
  order_item_uuid: string;
  order_uuid: string;
  owners: number[];
  payment_type: string[];
  product_number: string;
  product_uuid: string;
  quantity: number;
  received_date: string;
  retail: number;
  salesperson_id: number;
  salesperson_name: string;
  serial: string;
  shipping_city: string;
  shipping_postal: string;
  shipping_state: string;
  shipping_street: string;
  shipping_street_2: string;
  sku: string;
  sku_category: string;
  sku_category_code: string;
  sku_uuid: string;
  status: string;
  tax: number;
  tracking_number?: string;
  transaction_date: string;
  transaction_id: string;
  transaction_type: string;
  warranty: string;
  wholesale: number;
  vendor_code: string;
  vendor_id: number;
  vendor_name: string;
}
