import { i18nNamespaces } from 'i18n';
import { useTranslation } from 'react-i18next';
import { translate } from 'utils/translation';

export const PASSWORD_RESET_TEXT = 'Reset Your Password';
export const VENDOR_SEARCH_TITLE = 'Include In Browse Inventory';
export const INCLUDE_ECOMMERCE = 'Include In E-Commerce';
export const SERVER_DOWN = 'The server is currently undergoing maintenance. Please check back soon!';
export const PLACEHOLDER_IMAGE_SRC = 'https://diam-general.s3.us-east-2.amazonaws.com/general/item_placeholder.png';
export const PDF_WORKER_URL = 'https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js';

export const ALLOCATED_OPTIONS = [
  { key: 0, value: 'All' },
  { key: 1, value: 'Allocated' },
  { key: 2, value: 'Not Allocated' },
];

export const STOCK_OPTIONS = [
  { key: 0, value: 'All', textKey: 'all' },
  { key: 1, value: 'In Stock', textKey: 'instock' },
  { key: 2, value: 'Out of Stock', textKey: 'outofstock' },
];

export const STATUS_OPTIONS = [
  { key: 0, value: 'All' },
  { key: 1, value: 'Open' },
  { key: 2, value: 'Closed' },
  { key: 3, value: 'Canceled' },
];

export const TRANSFER_STATUS_OPTIONS = [
  { value: 1, label: 'All' },
  { value: 2, label: 'Pending' },
  { value: 3, label: 'Completed' },
];

export const TAXABLE_OPTIONS = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Taxable' },
  { value: 2, label: 'Not Taxable' },
];

export const REPORT_STOCK_OPTIONS = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Stock #s' },
  { value: 2, label: 'Nonstock #s' },
];

export const CARD_TYPE_OPTIONS = [
  { value: 1, label: 'Advertising' },
  { value: 2, label: 'Donation' },
  { value: 3, label: 'Sold' },
  { value: 4, label: 'Other' },
];

export const CLIENT_CONTACT_TYPES = [
  { key: 0, value: 'Home' },
  { key: 1, value: 'Work' },
  { key: 2, value: 'Mobile' },
];

export const NEW_CLIENT_CONTACT_TYPES = [
  { value: 'Home', label: 'Home' },
  { value: 'Work', label: 'Work' },
  { value: 'Mobile', label: 'Mobile' },
];

export const MEMO_OPTIONS = [
  { value: 0, label: translate('reports', 'filters.memo-options.all', 'All') },
  { value: 1, label: translate('reports', 'filters.memo-options.memo', 'Memo') },
  { value: 2, label: translate('reports', 'filters.memo-options.asset', 'Asset') },
];

export const ACCOUNT_TYPES = [
  { value: 1, label: 'Vendor' },
  { value: 2, label: 'Retailer' },
  { value: 3, label: 'Partner' },
  { value: 4, label: 'Manufacturer' },
];

export const useBooleanOptions = () => {
  const { t } = useTranslation([i18nNamespaces.Common]);
  return [
    { value: 0, label: t('common:all') },
    { value: 1, label: t('common:no') },
    { value: 2, label: t('common:yes') },
  ];
};

export const usePurchaseOrderStatusOptions = () => {
  const { t } = useTranslation([i18nNamespaces.Common]);
  return [
    { value: 0, label: t('all') },
    { value: 1, label: t('open') },
    { value: 2, label: t('closed') },
  ];
};

export const USA_STATE_CODES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const CRYSTAL_DEMO_SYSTEM_NAME = 'crystal-demo';

export const DEFAULT_CONVERSION_POTENTIAL = 0;

export const useEstimatedConversionPotentialOptions = () => {
  const { t } = useTranslation([i18nNamespaces.Clients, i18nNamespaces.Common]);

  const EstimatedConversionPotentialOptions = [
    {
      value: DEFAULT_CONVERSION_POTENTIAL,
      label: t('not-applicable', { ns: i18nNamespaces.Clients }),
    },
    {
      value: 4,
      label: t('priority-options.very-high', {
        ns: i18nNamespaces.Clients,
      }),
    },
    {
      value: 3,
      label: t('priority-options.high', {
        ns: i18nNamespaces.Clients,
      }),
    },
    {
      value: 2,
      label: t('priority-options.medium', {
        ns: i18nNamespaces.Clients,
      }),
    },
    {
      value: 1,
      label: t('priority-options.low', {
        ns: i18nNamespaces.Clients,
      }),
    },
  ];

  return EstimatedConversionPotentialOptions;
};

export const NOT_AVAILABLE = 'N/A';

export const EMPTY = '';

export const JobTypesEnum = {
  JewelryRepair: 1,
  WatchRepair: 2,
  Appraisal: 3,
  Evaluation: 4,
};

export const FORM_APPRAISAL_SUBMITTED = 1;
