import { Link } from 'react-router-dom';
import { formatMoney } from '../../../utils/money';
import { translate } from '../../../utils/translation';

export const FINANCE_CHARGES_REPORT_FIELDS = [
  {
    name: translate('reports', 'finance-charges.columns.last-name', 'Last Name'),
    key: 'client_last_name',
    id: 'client_last_name',
    filterable: true,
    isDefault: true,
    sortable: true,
  },
  {
    name: translate('reports', 'finance-charges.columns.first-name', 'First Name'),
    key: 'client_first_name',
    id: 'client_first_name',
    filterable: true,
    isDefault: true,
    sortable: true,
  },
  {
    name: translate('reports', 'finance-charges.columns.client-number', 'Client #'),
    id: 'client_record_id',
    key: 'client_record_id',
    type: 'LINK',
    filterable: true,
    render: (row: any) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row?.client_uuid}`}
      >
        {row.client_record_id}
      </Link>
    ),
    isDefault: true,
  },
  {
    name: translate('reports', 'finance-charges.columns.finance-charges', 'Finance Charges'),
    key: 'finance_charges',
    id: 'finance_charges',
    type: 'NUMBER',
    render: (row: any) => formatMoney(row.finance_charges),
    isDefault: true,
    sortable: true,
  },
];
